// Libs
import React from 'react';
import { navigate } from 'gatsby';

// Styles
import { Container } from './styles';

// Images and Icons
import BackArrow from '../../assets/icons/ic-arrow-left.svg';

const ButtonArrowBack = ({ children, ...rest }) => {
  return (
    <Container
      type="button"
      {...rest}
      onClick={e => {
        e.preventDefault();
        navigate('/');
      }}
    >
      {children}
      <BackArrow width="23" height="32" />
    </Container>
  );
};

export default ButtonArrowBack;
