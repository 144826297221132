// Libs
import React from 'react';
import { navigate } from 'gatsby';

// Components
import SEO from '../../components/seo';
import HeaderPetshopRegister from '../../components/HeaderPetshopRegister';
import AlcanceError from '../../components/AlcanceError';

function AlcanceErrorPage() {
  
  return (
    <>
      <SEO title="Falha ao finalizar cadastro." />

      <HeaderPetshopRegister percentProgressLine="100%" />

      <AlcanceError/>
    </>
  );
}

export default AlcanceErrorPage;