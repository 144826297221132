import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  background: white;
  height: 90px;
  align-items: center;
  padding: 0 32px;

  > img {
    padding: 0;
    margin: 0;
    margin-left: calc(50% - 106px);
  }
`;

export const ProgressLine = styled.div`
  width: 100%;
  height: 10px;
  background: #d6d8db;
  display: flex;

  &:before {
    content: '';
    /* Por enquanto colocamos
    uma largura e uma cor
    com valores fixos
    */
    width: ${props => props.percentProgressLine && props.percentProgressLine};
    background-color: #fbc02d;
  }
`;
