// Libs
import React from 'react';

// Styles
import { Container, ProgressLine } from './styles';

// Components
import ButtonArrowBack from '../ButtonArrowBack';

// Images and Icons

import IpetisLogo from '../../assets/images/logo-horizontal-roxo.svg';

function HeaderPetshopRegister({ percentProgressLine }) {
  return (
    <>
      <Container>
        <ButtonArrowBack />
        <img src={IpetisLogo} alt="Logo" width="140" height="130"/>
      </Container>
      <ProgressLine percentProgressLine={percentProgressLine} />
    </>
  );
}

export default HeaderPetshopRegister;
