import React from 'react';
import { navigate } from "gatsby"

import { Container } from './styles';

import ImageMap from '../../assets/images/undraw_map_1r69.svg';

function AlcanceError() {

    const handleHomeButton = () => {
        navigate('/')
    }

    return (
        <Container>
            
            <div className="circle"></div>
            

            <div className="content">
                <img src={ImageMap} alt="Map" width="384" height="384"/>


                <div className="info-content">
                    <h1>Ah não! A animAU ainda não está disponível para sua região.</h1>
                    <p>Mas calma, já temos seu e-mail. Assim que tivermos disponibilidade nós entraremos em contato.</p>

                    <button 
                    type="button" 
                    className="btn-voltar"
                    onClick={handleHomeButton}
                    >Voltar para a home</button>
                </div>
                
            </div>
            
        </Container>
    )
}

export default AlcanceError