import styled from 'styled-components';

export const Container = styled.div`
    width: calc(100vw - 100px);
    height: calc(100vh - 190px);
    position: relative;
    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: center;

    .content {
        display: flex;
        align-items: center;
        gap: 2rem;
    }

    .info-content {
        font-family: Roboto, Arial, sans-serif;
        color: #1d211c;
        max-width: 500px;
        p {
            color: #747a85;
        }

        display: flex;
        flex-direction: column;

        .btn-voltar {
            margin-top: 1rem;
            align-self: center;
            background-color: #472465;
            color: #fff;
            border: none;
            padding: 1rem;
            border-radius: 8px;
            cursor: pointer;
            transition: filter .2s;

            &:hover {
                filter: brightness(.9);
            }
        }
    }

    .circle {
        background: #6C63FF10;
        width: 700px;
        height: 700px;
        border-radius: 100%;
        position: absolute;
        z-index: -100;
        top: 1rem;
        right: 40%;
    }
`